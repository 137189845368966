import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import { container, info } from './graj-w-klasyke-prize.module.scss';

const prizeImg = '../../assets/images/graj-w-klasyke/prizes.png';

interface IGrajWKlasykePrizeProps {
    className?: string;
}

const GrajWKlasykePrize: React.FC<IGrajWKlasykePrizeProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            <div>
                <StaticImage src={prizeImg} alt="" />
            </div>
            <p className={info}>
                {t('graj.w.klasyke.prize.info.1')} <br />
                {t('graj.w.klasyke.prize.info.2')}
            </p>
        </div>
    );
};

export default GrajWKlasykePrize;
