import React from 'react';

import { container, title, accent, icon } from './graj-w-klasyke-contest-thank-you.module.scss';
import IconSent from '../../assets/images/svg/icon_completed.svg';

export interface IGrajWKlasykeContestThankYouProps {
    className?: string;
    titleFirstLine?: string;
    titleSecondLine?: string;
}

const GrajWKlasykeContestThankYou: React.FC<IGrajWKlasykeContestThankYouProps> = ({
    className = '',
    titleSecondLine,
    titleFirstLine,
}) => {
    return (
        <div className={`${container} ${className}`}>
            <IconSent className={icon} />
            {(titleFirstLine || titleSecondLine) && (
                <h1 className={title}>
                    {titleFirstLine && <span>{titleFirstLine}</span>}
                    {titleSecondLine && <span className={accent}>{titleSecondLine}</span>}
                </h1>
            )}
        </div>
    );
};

export default GrajWKlasykeContestThankYou;
