import React from 'react';

import {
    layout,
    container,
    contact,
    header,
    thanks,
    headerContent,
    mainLayout,
} from './graj-w-klasyke-layout.module.scss';
import { grid } from '../styles/grid.module.scss';
import staticFiles from '../config/static-files';

import MainLayout, { IMainLayoutProps } from './main-layout';
import GrajWKlasykeContestThankYou, {
    IGrajWKlasykeContestThankYouProps,
} from '../components/organisms/graj-w-klasyke-contest-thank-you';
import GrajWKlasykeContact from '../components/organisms/graj-w-klasyke-contact';

interface IGrajWKlasykeLayout extends IMainLayoutProps {
    children?: React.ReactNode;
    headerProps?: IGrajWKlasykeContestThankYouProps;
    layoutClass?: string;
    bannerClass?: string;
    isMainPage?: boolean;
}

const GrajWKlasykeLayout: React.FC<IGrajWKlasykeLayout> = ({
    children,
    className = '',
    layoutClass = '',
    bannerClass,
    headerProps,
    isMainPage,
    ...rest
}) => {
    return (
        <MainLayout
            {...rest}
            className={`${layout} ${layoutClass}`}
            bannerClass={bannerClass}
            containerClassName={mainLayout}
        >
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    {headerProps && (
                        <GrajWKlasykeContestThankYou {...headerProps} className={thanks} />
                    )}
                </div>
            </div>
            <div className={`${container} ${grid}`}>
                <div className={`${className}`}>{children}</div>
                <GrajWKlasykeContact
                    className={contact}
                    regulationsUrl={staticFiles.grajWKlasykeRegulations}
                    isMainPage={isMainPage}
                />
            </div>
        </MainLayout>
    );
};

export default GrajWKlasykeLayout;
