// extracted by mini-css-extract-plugin
export var backgroundAnimation = "graj-w-klasyke-contact-module--backgroundAnimation--0ee22";
export var box = "graj-w-klasyke-contact-module--box--5d704";
export var container = "graj-w-klasyke-contact-module--container--3ed33";
export var content = "graj-w-klasyke-contact-module--content--6e20b";
export var divider = "graj-w-klasyke-contact-module--divider--35483";
export var errorBlink = "graj-w-klasyke-contact-module--error-blink--55e53";
export var img = "graj-w-klasyke-contact-module--img--1f050";
export var label = "graj-w-klasyke-contact-module--label--a81e6";
export var leaf = "graj-w-klasyke-contact-module--leaf--dce71";
export var left = "graj-w-klasyke-contact-module--left--6392f";
export var link = "graj-w-klasyke-contact-module--link--7a0d0";
export var mobile = "graj-w-klasyke-contact-module--mobile--3ddcf";
export var mobileImages = "graj-w-klasyke-contact-module--mobile-images--10b84";
export var phone = "graj-w-klasyke-contact-module--phone--ec89b";
export var right = "graj-w-klasyke-contact-module--right--b20c2";
export var splash = "graj-w-klasyke-contact-module--splash--001aa";
export var title = "graj-w-klasyke-contact-module--title--a2616";
export var vinyl = "graj-w-klasyke-contact-module--vinyl--25367";