import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, prize } from './thank-you.module.scss';
import { IPage } from '../../../models/page.model';

import GrajWKlasykeLayout from '../../../layouts/graj-w-klasyke-layout';
import GrajWKlasykePrize from '../../../components/organisms/graj-w-klasyke-prize';

interface IGrajWKlasykeThankYouPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const ThankYou: React.FC<IGrajWKlasykeThankYouPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;

    return (
        <GrajWKlasykeLayout
            page={page}
            className={container}
            headerProps={{
                titleFirstLine: t('graj.w.klasyke.thank.title.1'),
                titleSecondLine: t('graj.w.klasyke.thank.title.2'),
            }}
        >
            <GrajWKlasykePrize className={prize} />
        </GrajWKlasykeLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ThankYou;
